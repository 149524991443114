/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 100px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eaeaea;
  border-radius: 100px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(0, 69, 34);
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(41, 160, 39);
}
