@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit&display=swap");

@layer components {
  .md-player {
    @apply top-auto bottom-0 bg-red-900 w-full h-[90px];
  }
  .md-lottie {
    @apply h-[100px] w-[100px];
  }
  .md-lettering {
    @apply text-[15px] animate-none;
  }
  .md-letter-title {
    @apply text-[12px] mb-3;
  }
  .md-vinyl {
    @apply h-[60px] w-[60px] ml-4;
  }
  .md-progress-bar {
    @apply w-full absolute top-0 my-0 left-0 h-[2px];
  }
  .md-sticker-footer {
    @apply h-56 w-[82%] bg-transparent -z-10 top-[65px] items-center justify-center text-white flex;
  }
  .md-indicator {
    @apply w-[90%] grid grid-cols-3 grid-flow-row gap-4 p-4 text-white sticky top-0 bg-stone-900 mt-0 z-30;
  }
  .md-hr {
    @apply border-green-600 text-white border-[0.5px] w-full mt-2 sticky top-14 z-[31] flex;
  }
  .md-sidebar-mobile {
    @apply flex bg-black w-[250px] h-screen fixed z-[50] top-0 -left-[100%] transition-all duration-300;
  }
  .md-sidebar-mobile-active {
    @apply left-0 transition-all duration-300 flex w-[250px] h-screen fixed top-0 bg-black z-[50];
  }
  .md-barrier {
    @apply top-auto bottom-0 bg-stone-900 w-full h-[90px];
  }
}

body {
  margin: 0;
  background-color: rgb(28 25 23);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
